import { defineStore } from 'pinia';
import { accounts as GoogleAccounts } from 'google-one-tap';
import Vue, { computed, ref } from 'vue';
import { ISemiLoginData } from '@/interfaces/ISemiLoginData';
import { SemiLoginType } from '@/enums/SemiLoginType';
import jwtDecoder from 'jwt-decode';
import { handleDates } from '@/util/dates';
import { useCustomerStore } from '@/store/customer';
import sub from 'date-fns/sub';
import { useGlobalStore } from '@/store/global';

interface SSO {
    google?: GoogleAccounts;
}

export const useSemiLoginStore = defineStore(
    'semilogin',
    () => {
        // give access to customerStore and globalStore here
        const customerStore = useCustomerStore();
        const globalStore = useGlobalStore();

        const SSOObject = ref<SSO>({
            google: undefined,
        });

        const state = ref<ISemiLoginData>({
            type: SemiLoginType.MAIL_ADDRESS,
            email: undefined,
            email_sso: undefined,
            phone_number: undefined,
            webToken: undefined,
            timestamp: undefined,
            logoutRequested: false,
            loggedIn: false,
            seenPriceDetailPageOnce: false,
        });

        const isSSOLoggedIn = computed<boolean>(() => {
            return !!state.value.email_sso;
        });

        function logout() {
            state.value.logoutRequested = true;
            state.value.email_sso = '';
        }

        /**
         * Load the external javascript assets:
         *  - Google GSI Client
         */
        async function loadObjects() {
            // @ts-ignore
            await Vue.loadScript('https://accounts.google.com/gsi/client');

            // assign the retrieved object to global store.
            SSOObject.value.google = google.accounts;
        }

        /**
         * Init the Google ID stuff.
         */
        function initGoogle() {
            SSOObject.value.google?.id.initialize({
                client_id:
                    '973101504714-akd1b3u6pd0lfe2q7kakqpt7jbptplgs.apps.googleusercontent.com',
                callback: (res) => {
                    state.value.type = SemiLoginType.GOOGLE;
                    // @ts-ignore
                    state.value.email_sso = jwtDecoder(res.credential).email;
                    state.value.webToken = res.credential;
                },
            });

            if (!isSSOLoggedIn.value) {
                SSOObject.value.google?.id.renderButton(
                    document.getElementById('google-login-button') as HTMLElement,
                    { theme: 'outline', size: 'medium', width: 250 }, // customization attributes
                );
            }
        }

        /**
         * Unlock the configurator by creating a new timestamp and writing the mail address to store.
         */
        function unlockConfigurator(email: string | undefined) {
            if (isSSOLoggedIn.value) {
                if (!state.value.email_sso) {
                    throw new Error('Doing SSO but email in store is undefined');
                }

                // write the SSO email to customer store and set the semilogin_type
                customerStore.customer.semilogin_type = state.value.type;
                customerStore.customer.initial_country_code = globalStore.country;
                customerStore.customer.mail_address = state.value.email_sso;
                state.value.email = state.value.email_sso;
            } else {
                if (!email) {
                    throw new Error('Doing via custom email but given address is undefined');
                }

                // login via custom email, write to customer and this store
                customerStore.customer.initial_country_code = globalStore.country;
                customerStore.customer.mail_address = email;
                state.value.email = email;
            }

            state.value.loggedIn = true;
            // write the timestamp to this store
            state.value.timestamp = new Date();
        }

        /**
         * Check for an expired login state (timestamp older than 30 days).
         */
        const isExpired = computed<boolean>(() => {
            if (!state.value.timestamp) {
                // undefined timestamp is expired (Should eventually be allowed since user may have gone back to selector before finishing the login process, thus having to re-login)
                return true;
            }

            // substract 30 days from current
            const minValid = sub(new Date(), { days: 30 });

            return minValid > state.value.timestamp;
        });

        /**
         * Call all three init functions.
         */
        function checkStateExpiration() {
            if (isExpired.value) {
                // login state is expired, reset it
                reset();
            }
        }

        /**
         * Reset the semilogin state
         */
        function reset() {
            state.value.type = SemiLoginType.MAIL_ADDRESS;
            state.value.email = undefined;
            state.value.email_sso = undefined;
            state.value.webToken = undefined;
            state.value.timestamp = undefined;
            state.value.phone_number = undefined;
            state.value.logoutRequested = false;
            state.value.loggedIn = false;
        }

        return {
            state,
            isSSOLoggedIn,
            loadObjects,
            checkStateExpiration,
            initGoogle,
            unlockConfigurator,
            logout,
        };
    },
    {
        // store the state into localstorage
        persist: {
            paths: ['state'],
            serializer: {
                /**
                 * Provide own deserialize function to parse date strings into real date objects.
                 * @param value
                 */
                deserialize: (value) => {
                    const obj = JSON.parse(value);
                    handleDates(obj);

                    return obj;
                },
                serialize: JSON.stringify,
            },
        },
    },
);
